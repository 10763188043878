// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import $api, { $url } from '../configs/axiosConfig';

export const getData = createAsyncThunk(
  'customers/getData',
  async (params, { rejectWithValue }) => {
    try {
      const response = await $api.get(`${$url}/customers`, { params });
      return {
        params,
        data: response.data.customers,
        total: response.data.total,
      };
    } catch (error) {
      console.log('fetch customers error', error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOneCustomer = createAsyncThunk(
  'customers/getOneCustomer',
  async (id, { rejectWithValue }) => {
    try {
      const response = await $api.get(`${$url}/customers/${id}`);
      return response.data;
    } catch (error) {
      console.log('get one customer error', error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const addCustomer = createAsyncThunk(
  'customers/addCustomer',
  async (customer, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await $api.post(`${$url}/customers/new`, {
        ...customer,
      });
      await dispatch(getData(getState().customers.params));
      return response.data;
    } catch (error) {
      console.log('add customer error', error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  'customers/updateCustomer',
  async ({ id, customer }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await $api.put(`${$url}/customers/${id}`, {
        ...customer,
      });
      await dispatch(getData(getState().customers.params));
      return response.data;
    } catch (error) {
      console.log('update customer error', error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeCustomer = createAsyncThunk(
  'customers/removeCustomer',
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      await $api.delete(`${$url}/customers/${id}`);
      await dispatch(getData(getState().customers.params));
      return id;
    } catch (error) {
      console.log('delete customer error', error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const customersSlice = createSlice({
  name: 'customers',
  initialState: {
    data: [],
    params: {
      s: '',
      sort: '',
      order: '',
      page: 1,
    },
    total: null,
    loading: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: {
    [getData.pending]: (state) => {
      state.loading = 'pending';
    },
    [getData.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.params = payload.params;
      state.total = payload.total;
      state.loading = 'idle';
    },
    [getData.rejected]: (state, action) => {
      state.loading = 'failed';
      console.log('error fetching customers', action);
      state.error = action.error.message || '';
    },
    [getOneCustomer.pending]: (state) => {
      state.loading = 'pending';
    },
    [getOneCustomer.fulfilled]: (state) => {
      state.loading = 'idle';
    },
    [getOneCustomer.rejected]: (state, action) => {
      state.loading = 'failed';
      console.log('error fetching one customer', action);
      state.error = action.error.message || '';
    },
    [addCustomer.pending]: (state) => {
      state.loading = 'pending';
    },
    [addCustomer.fulfilled]: (state, { payload }) => {
      // state.result = payload.data;
      state.loading = 'idle';
    },
    [addCustomer.rejected]: (state, action) => {
      state.loading = 'failed';
      console.log('error adding customer', action);
      state.error = action.error.message || '';
    },
    [updateCustomer.pending]: (state) => {
      state.loading = 'pending';
    },
    [updateCustomer.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.loading = 'idle';
    },
    [updateCustomer.rejected]: (state, action) => {
      state.loading = 'failed';
      console.log('error updating customer', action);
      state.error = action.error.message || '';
    },
    [removeCustomer.pending]: (state) => {
      state.loading = 'pending';
    },
    [removeCustomer.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.loading = 'idle';
    },
    [removeCustomer.rejected]: (state, action) => {
      state.loading = 'failed';
      console.log('remove customer error', action);
      state.error = action.error.message || '';
    },
  },
});

export const customersEntities = ({ customers }) => {
  const isLoading = customers.loading === 'pending';
  return {
    customers: customers.data,
    isLoading,
    total: customers.total,
    error: customers.error,
    result: customers.result,
  };
};

export default customersSlice.reducer;
