// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

//** Axios Imports
import $api, { $url } from '../configs/axiosConfig';

export const getData = createAsyncThunk(
  'services/getData',
  async (params, { rejectWithValue }) => {
    try {
      const response = await $api.get(`${$url}/services`, { params });
      const servicesData = response.data.services?.map((data) => ({
        ...data,
        language: { value: data.language, label: data.language },
        type: { value: data.type, label: data.type },
        currency: { value: data.currency, label: data.currency },
      }));
      return {
        params,
        data: servicesData,
        total: response.data.total,
      };
    } catch (error) {
      console.log('fetch services error', error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getService = createAsyncThunk(
  'services/getService',
  async ({ id }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await $api.get(`${$url}/services/${id}`);
      return response.data;
    } catch (error) {
      console.log('get one service error', error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const addService = createAsyncThunk(
  'services/addService',
  async (service, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await $api.post(`${$url}/services/new`, { ...service });
      await dispatch(getData(getState().services.params));
      return response.data;
    } catch (error) {
      console.log('add service error', error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateService = createAsyncThunk(
  'services/updateService',
  async ({ id, service }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await $api.put(`${$url}/services/${id}`, { ...service });
      await dispatch(getData(getState().services.params));
      return response.data;
    } catch (error) {
      console.log('update service error', error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeService = createAsyncThunk(
  'services/removeService',
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      $api.delete(`${$url}/services/${id}`);
      await dispatch(getData(getState().services.params));
      return id;
    } catch (error) {
      console.log('remove service error', error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const servicesSlice = createSlice({
  name: 'services',
  initialState: {
    data: [],
    params: {
      s: '',
      sort: '',
      order: '',
      page: 1,
    },
    total: null,
    loading: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: {
    [getData.pending]: (state) => {
      state.loading = 'pending';
    },
    [getData.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.params = payload.params;
      state.total = payload.total;
      state.loading = 'idle';
    },
    [getData.rejected]: (state, action) => {
      state.loading = 'failed';
      console.log('error fetching services', action);
      state.error = action.error.message || '';
    },
    [getService.pending]: (state) => {
      state.loading = 'pending';
    },
    [getService.fulfilled]: (state) => {
      state.loading = 'idle';
    },
    [getService.rejected]: (state, action) => {
      state.loading = 'failed';
      console.log('error fetching one service', action);
      state.error = action.error.message || '';
    },
    [addService.pending]: (state) => {
      state.loading = 'pending';
    },
    [addService.fulfilled]: (state) => {
      state.loading = 'idle';
    },
    [addService.rejected]: (state, action) => {
      state.loading = 'failed';
      console.log('error adding service', action);
      state.error = action.error.message || '';
    },
    [updateService.pending]: (state) => {
      state.loading = 'pending';
    },
    [updateService.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.loading = 'idle';
    },
    [updateService.rejected]: (state, action) => {
      state.loading = 'failed';
      console.log('error updating service', action);
      state.error = action.error.message || '';
    },
    [removeService.pending]: (state) => {
      state.loading = 'pending';
    },
    [removeService.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.loading = 'idle';
    },
    [removeService.rejected]: (state, action) => {
      state.loading = 'failed';
      console.log('delete service error', action);
      state.error = action.error.message || '';
    },
  },
});

export const servicesEntities = ({ services }) => {
  const isLoading = services.loading === 'pending';
  return {
    services: services.data,
    isLoading,
    total: services.total,
    error: services.error,
  };
};

export default servicesSlice.reducer;
