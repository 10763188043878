// ** Redux Imports
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
//** Axios Imports
import $api, { $url } from '../../../../configs/axiosConfig';
import { getData } from '@redux/quotes';
import { roundToTwo } from '../../../../utility/Utils';

export const createQuote = createAsyncThunk(
  'quotes/addQuote',
  async (_, { dispatch, getState, rejectWithValue }) => {
    const quote = { ...getState().quote };
    quote.customer = quote.selectedCustomer._id;
    delete quote.selectedCustomer;

    try {
      const response = await $api.post(`${$url}/quotes/new`, quote);
      // await dispatch(getData(getState().quotes));
      return response.data;
    } catch (error) {
      console.log('add quote error', error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getQuote = createAsyncThunk(
  'quotes/getQuote',
  async (quoteId, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await $api.get(`${$url}/quotes/${quoteId}`);

      return response.data;
    } catch (error) {
      console.log('add quote error', error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateQuote = createAsyncThunk(
  'quotes/updateQuote',
  async (id, { dispatch, getState, rejectWithValue }) => {
    const quote = { ...getState().quote };
    quote.customer = quote.selectedCustomer._id;
    console.log('updateQuote quote data', quote);
    delete quote.selectedCustomer;
    try {
      const response = await $api.put(`${$url}/quotes/${id}`, quote);
      return response.data;
    } catch (error) {
      console.log('update quote error', error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  language: 'English',
  currency: 'Shekel',
  projectType: 'Hourly',
  date: new Date(),
  quoteTitle: '',
  selectedCustomer: {},
  services: [],
  optionalServices: [],
  total: 0,
  discountType: 'None',
  discount: 0,
  totalAfterDiscount: 0,
  totalIncludesVat: 0,
  paymentDetails: '',
  status: 'Draft',
};

const setQuoteFromApi = (state, quote) => {
  state.language = quote.language;
  state.currency = quote.currency;
  state.projectType = quote.projectType;
  state.date = quote.date;
  state.quoteTitle = quote.quoteTitle;
  state.selectedCustomer = quote.customer;
  state.services = quote.services || [];
  state.optionalServices = quote.optionalServices || [];
  state.total = quote.total;
  state.discountType = quote.discountType;
  state.discount = quote.discount;
  state.totalAfterDiscount = quote.totalAfterDiscount;
  state.totalIncludesVat = quote.totalIncludesVat;
  state.paymentDetails = quote.paymentDetails;
  state.status = quote.status;

  return state;
};

export const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    initState: () => initialState,
    setOption: (state, { payload: { field, value } }) => {
      state[field] = value;
    },
    setDate: (state, { payload }) => {
      state.date = payload;
    },
    setQuoteTitle: (state, { payload }) => {
      state.quoteTitle = payload;
    },
    setSelectedCustomer: (state, { payload }) => {
      state.selectedCustomer = payload;
    },

    addService: (state, { payload: isOptionalServices }) => {
      const service = {
        service: null,
        quantity: 1,
        total: 0,
        customDetails: '',
      };
      if (isOptionalServices) state.optionalServices.push(service);
      else state.services.push(service);
    },
    setSerivce: (
      state,
      { payload: { index, field, value, isOptionalServices } }
    ) => {
      if (isOptionalServices) state.optionalServices[index][field] = value;
      else state.services[index][field] = value;
    },
    removeService: (state, { payload }) => {
      state.services.splice(payload, 1);
    },
    removeAllServices: (state) => {
      state.services = [];
      state.total = 0;
      state.discountType = 'None';
      state.discount = 0;
      state.totalAfterDiscount = 0;
      state.totalIncludesVat = 0;
    },

    setTotal: (state) => {
      if (!state.services.length) {
        state.total = 0;
        state.totalAfterDiscount = 0;
        state.totalIncludesVat = 0;
        return;
      }

      const total = state.services.reduce((a, b) => ({
        total: a.total + b.total,
      })).total;
      state.total = total;
      if (state.discount !== 'None') {
        const totalAfterDiscount =
          state.discountType === 'Amount'
            ? total - state.discount
            : total - ((state.discount / 100) * total);
        state.totalAfterDiscount = Math.round(totalAfterDiscount);
        state.totalIncludesVat = Math.round(totalAfterDiscount * 1.17);
      } else {
        state.totalIncludesVat = Math.round(total * 1.17);
      }
    },
    setDiscountType: (state, { payload }) => {
      state.discountType = payload;
    },
    setDiscount: (state, { payload }) => {
      state.discount = Number(payload);
    },

    setPaymentDetails: (state, { payload }) => {
      state.paymentDetails = payload;
    },
  },
  extraReducers: {
    [createQuote.fulfilled]: () => { },
    [createQuote.pending]: () => { },
    [createQuote.rejected]: () => { },

    [getQuote.fulfilled]: (state, { payload }) => {
      setQuoteFromApi(state, payload.data.quote);
    },
    [updateQuote.fulfilled]: (state, { payload }) => {
      setQuoteFromApi(state, payload.data.quote);
    },
  },
});

const { actions, reducer } = quoteSlice;

export const {
  initState,
  setOption,
  setQuoteTitle,
  setDate,
  setSelectedCustomer,
  addService,
  removeService,
  removeAllServices,
  setSerivce,
  setDiscountType,
  setDiscount,
  setTotal,
  setPaymentDetails,
} = actions;

export default reducer;
