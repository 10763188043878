// ** Reducers Imports
import layout from "./layout";
import navbar from "./navbar";
import auth from "./authentication";
import customers from "./customers";
import services from "./services";
import users from "./users";
import company from "./company";
import quotes from "./quotes";

import quote from "../views/pages/quote/store/index";

const rootReducer = {
  navbar,
  layout,
  auth,
  customers,
  services,
  users,
  company,
  quote,
  quotes,
};

export default rootReducer;
