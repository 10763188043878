// ** React Imports
import { useNavigate } from 'react-router-dom'

// ** Third Party Components
import { Power } from 'react-feather'

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'

import { useDispatch, useSelector } from 'react-redux'
import {
  logout,
} from "@redux/authentication";

const UserDropdown = () => {
  const store = useSelector(state => state)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const logoutHandler = async () => {
    await dispatch(logout())
    navigate('/login')
  }

  return (
    <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
      <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
        <div className='user-nav d-sm-flex d-none'>
          <span className='user-name fw-bold'>{store.auth.userData && store.auth.userData.firstName} {store.auth.userData && store.auth.userData.lastName}</span>
          <span className='user-status'>{store.auth.userData && store.auth.userData.role}</span>
        </div>
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem className='dropdown-logout'>
          <Power size={14} className='me-75' />
          <span className='align-middle' onClick={logoutHandler}>Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
