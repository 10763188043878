import { DefaultRoute } from "../router/routes";

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num);

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData");
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
// export const getHomeRouteForLoggedInUser = (role, company) => {
//   // console.log('user company utils', company);
//   // if (user) return DefaultRoute
//   if (company !== null) {
//     return DashboardRoute
//   } else {
//     return DefaultRoute
//   }
//   // }
// }
export const getHomeRouteForLoggedInUser = user => {
  const role = user.role
  const company = user.company
  // if (!company) return '/home'
  // if (role.toLowerCase === 'admin' && company) return DefaultRoute
  if (role) return DefaultRoute
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

//Period filter options
export const periodOptions = [
  { value: "Week", label: "Last Week" },
  { value: "Month", label: "Last Month" },
  { value: "Year", label: "Last year" },
];

export const statusOptions = [
  { value: "Draft", label: "Draft" },
  { value: "Sent", label: "Sent" },
  { value: "Progress", label: "In Progress" },
  { value: "Closed", label: "Closed Deal" },
];

export const permissionOptions = [
  { value: 'Admin', label: 'Admin', disabled: true },
  { value: 'Manager', label: 'Manager' },
  { value: 'User', label: 'User' },
]

export const languagesOptions = [
  { value: "English", label: "English" },
  { value: "Hebrew", label: "Hebrew" },
];

export const servicesTypeOptions = [
  { value: "Hourly", label: "Hourly" },
  { value: "Monthly", label: "Monthly" },
  { value: "Product", label: "Product" },
];

export const currencyOptions = [
  { value: "Shekel", label: "Shekel" },
  { value: "Dollar", label: "Dollar" },
  { value: "Euro", label: "Euro" },
];

export const discountOptions = [
  { value: "None", label: "None" },
  { value: "Amount", label: "Amount" },
  { value: "Percentage", label: "Percentage" },
];

export const tablePaginationValue = 7;

export const getCurrencySymbol = (currency) => {
  switch (currency) {
    case "Shekel":
      return "₪";
    case "Dollar":
      return "$";
    case "Euro":
      return "€";
    default:
      return "";
  }
};

export const emailSubjectOptions = [
  { value: 'Quote_Title', label: 'Quote Title' },
]

export const emailContentOptions = [
  { value: 'Contact_Name', label: 'Contact Name' },
  { value: 'User_First_Name', label: 'User First Name' },
]
export const addCommasToNumber = (number) => {
  if (typeof number !== "number") return "";

  return number
    .toFixed(0)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const FILE_MAX_SIZE_NAME = 5;
export const FILE_MAX_SIZE = FILE_MAX_SIZE_NAME * 1048576;

export const publicUrl = (path = '') => {
  return `${process.env.REACT_APP_SERVER_PUBLIC_URL}/${path}`;
};

export const roundToTwo = (num) => {
  // return +(Math.round(num + 'e+2') + 'e-2');
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export const getServicePriceTitle = (serviceType) => {
  switch (serviceType) {
    case 'Hourly':
      return 'Hours';
    case 'Monthly':
      return 'Months';
    // default:
    //   return 'Amount';
  }
};
