import axios from 'axios'
import Cookies from 'js-cookie'

export const $url = process.env.REACT_APP_SERVER_URL;

const $api = axios.create({
    withCredentials: true,
    baseURL: $url,
    headers: { 'Content-Type': 'application/json' }
})

$api.interceptors.request.use((config) => {
    const accessToken = Cookies.get('accessToken')
    config.headers.Authorization = `Bearer ${accessToken}`
    return config;
})

export default $api;