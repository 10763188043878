// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import $api, { $url } from "../configs/axiosConfig";

export const getData = createAsyncThunk(
  "company/getData", async (params, { rejectWithValue }) => {
    try {
      const response = await $api.get(`${$url}/settings/company`);
      return {
        data: response.data,
      };
    } catch (error) {
      console.log('company data error', error.response.data);
      return rejectWithValue(error.response.data)
    }
  });

export const addCompany = createAsyncThunk(
  "company/addCompany",
  async (company, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await $api.post(`${$url}/settings/company/new`, { ...company });
      console.log('add company result', response);
      await dispatch(getData(getState().company));
      return response.data;
    } catch (error) {
      console.log('add company error', error.response.data);
      return rejectWithValue(error.response.data)
    }
  }
);

export const updateCompany = createAsyncThunk(
  "company/updateCompany",
  async ({ company }, { dispatch, getState, rejectWithValue }) => {
    try {
      const formData = new FormData();

      for (const key in company) {
        formData.append(key, company[key]);
      }
      const response = await $api.put(`${$url}/settings/company`, formData, { 'Content-Type': 'multipart/form-data' });

      await dispatch(getData(getState().company));
      return response.data;
    } catch (error) {
      console.log('update company error', error.response.data);
      return rejectWithValue(error.response.data)
    }
  }

);

export const companySlice = createSlice({
  name: "company",
  initialState: {
    data: [],
    loading: "idle",
    error: null
  },
  reducers: {},
  extraReducers: {
    [getData.pending]: (state) => {
      state.loading = "pending";
    },
    [getData.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.loading = "idle";
    },
    [getData.rejected]: (state, action) => {
      state.loading = 'failed'
      console.log("error fetching company", action)
      state.error = action.error.message || ''
    },
    [addCompany.pending]: (state) => {
      state.loading = "pending";
    },
    [addCompany.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.loading = "idle";
    },
    [addCompany.rejected]: (state, action) => {
      state.loading = 'failed'
      console.log("error adding company", action)
      state.error = action.error.message || ''
    },
    [updateCompany.pending]: (state) => {
      state.loading = "pending";
    },
    [updateCompany.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.loading = "idle";
    },
    [updateCompany.rejected]: (state, action) => {
      state.loading = 'failed'
      console.log("error updating company", action)
      state.error = action.error.message || ''
    }
  },
});

export const companyEntities = ({ company }) => {
  const isLoading = company.loading === "pending";
  return { company: company.data, isLoading, error: company.error };
};

export default companySlice.reducer;
