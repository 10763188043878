// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'
import $api, { $url } from '../configs/axiosConfig'

// ** UseJWT import to get config
// import useJwt from '../@core/auth/jwt/useJwt'

// const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const register = createAsyncThunk(
  "authentication/register",
  async (params, { rejectWithValue }) => {
    try {
      const response = await $api.post(`${$url}/auth/register`, {
        firstName: params.firstName,
        lastName: params.lastName,
        email: params.email,
      });
      return response.data;
    } catch (error) {
      console.log('register error', error.response.data);
      return rejectWithValue(error.response.data)
    }
  }
);

export const registerConfirm = createAsyncThunk(
  "authentication/registerConfirm",
  async (params, { rejectWithValue }) => {
    const { email, hash } = params
    try {
      const response = await $api.post(`${$url}/auth/confirm`, { email, hash });
      return response.data;
    } catch (error) {
      console.log('confirm register error', error);
      return rejectWithValue(error.response.data)
    }
  }
);

export const login = createAsyncThunk(
  "authentication/login",
  async (email, { rejectWithValue }) => {
    try {
      const response = await $api.post(`${$url}/auth/login`, { ...email })
      return response.data;
    } catch (error) {
      console.log('login error', error.response.data);
      return rejectWithValue(error.response.data)
    }
  }
);

export const otp = createAsyncThunk(
  "authentication/otp",
  async (params, { rejectWithValue }) => {
    const { email, code } = params
    try {
      const response = await $api.post(`${$url}/auth/otp`, { email, code })
      console.log('otp response result', response.data);
      return response.data;
    } catch (error) {
      console.log('otp error', error.response.data);
      return rejectWithValue(error.response.data)
    }
  }
);

export const authCheck = createAsyncThunk(
  "authentication/authCheck",
  async (_, { rejectWithValue }) => {
    const accessToken = Cookies.get('accessToken')
    try {
      const response = await axios.get(`${$url}/auth/check`, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${accessToken}` }
      })
      // console.log('authCheck response result', response);
      return response.data;
    } catch (error) {
      console.log('authCheck error', error.response.data);
      return rejectWithValue(error.response.data)
    }
  }
)

export const logout = createAsyncThunk(
  "authentication/logout",
  async (params, { rejectWithValue }) => {
    try {
      const response = await $api.post(`${$url}/auth/logout`)
      console.log('logout response result', response.data);
    } catch (error) {
      console.log('logout error', error.response.data);
      return rejectWithValue(error.response.data)
    }
  }
);

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
    loading: "idle",
    error: null
  },
  reducers: {
    handleLogin: (state, action) => {
      console.log('handleLogin action', action);
      state.userData = action.payload.userData
      Cookies.set('accessToken', action.payload.accessToken)
      Cookies.set('refreshToken', action.payload.refreshToken)
      localStorage.setItem('userData', JSON.stringify(action.payload.userData))
    },
    handleLogout: state => {
      state.userData = {}
      localStorage.removeItem('userData')
      Cookies.remove('accessToken')
      Cookies.remove('refreshToken')
    },
    updateUserData(state, { payload }) {
      state.userData = payload;
      localStorage.setItem('userData', JSON.stringify(payload));
    },
  },
  extraReducers: {
    [register.pending]: (state) => {
      state.loading = "pending";
    },
    [register.fulfilled]: (state, { payload }) => {
      state.userData = payload.userData;
      state.loading = "idle";
    },
    [register.rejected]: (state, action) => {
      state.loading = 'failed'
      console.log("register error", action)
      state.error = action.error.message || ''
    },
    [registerConfirm.pending]: (state) => {
      state.loading = "pending";
    },
    [registerConfirm.fulfilled]: (state, { payload }) => {
      state.userData = payload.userData;
      state.loading = "idle";
    },
    [registerConfirm.rejected]: (state, action) => {
      state.loading = 'failed'
      console.log("confirm register error", action)
      state.error = action.error.message || ''
    },
    [login.pending]: (state) => {
      state.loading = "pending";
    },
    [login.fulfilled]: (state, { payload }) => {
      state.userData = payload.userData;
      state.loading = "idle";
    },
    [login.rejected]: (state, action) => {
      state.loading = 'failed'
      console.log("login error", action)
      state.error = action.error.message || ''
    },
    [otp.pending]: (state) => {
      state.loading = "pending";
    },
    [otp.fulfilled]: (state, { payload }) => {
      state.userData = payload.userData;
      state.loading = "idle";
    },
    [otp.rejected]: (state, action) => {
      state.loading = 'failed'
      console.log("otp error", action)
      state.error = action.error.message || ''
    },
    [authCheck.pending]: (state) => {
      state.loading = "pending";
    },
    [authCheck.fulfilled]: (state) => {
      state.loading = "idle";
    },
    [authCheck.rejected]: (state, action) => {
      state.loading = 'failed'
      console.log("authCheck error", action)
      state.error = action.error.message || ''
    },
    [logout.pending]: (state) => {
      state.loading = "pending";
    },
    [logout.fulfilled]: (state, { payload }) => {
      state.loading = "idle";
      Cookies.remove('accessToken')
      Cookies.remove('refreshToken')
      localStorage.removeItem('userData')
    },
    [logout.rejected]: (state, action) => {
      state.loading = 'failed'
      console.log("logout error", action)
      state.error = action.error.message || ''
    },
  },
})

export const { handleLogin, handleLogout, updateUserData } = authSlice.actions

export default authSlice.reducer
