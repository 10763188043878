// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import $api, { $url } from "../configs/axiosConfig";

export const getData = createAsyncThunk(
    "quotes/getData", async (params, { rejectWithValue }) => {
        try {
            const response = await $api.get(`${$url}/quotes`, { params });
            await new Promise(r => setTimeout(() => r(1), 400));
            return {
                data: response.data.quotes,
                total: response.data.total
            };
        } catch (error) {
            console.log('fetch quotes error', error.response.data);
            return rejectWithValue(error.response.data)
        }
    }
);

export const getCustomerQuotes = createAsyncThunk(
    "quotes/getCustomerQuotes", async ({ id, params }, { rejectWithValue }) => {
        try {
            const response = await $api.get(`${$url}/quotes/customers/${id}`, { params })
            return response.data;
        } catch (error) {
            console.log('fetch customer quotes error', error?.response?.data);
            return rejectWithValue(error.response.data)
        }
    }
);

//quote status changing(patch update)
export const updateQuoteStatus = createAsyncThunk(
    "quotes/updateQuoteStatus", async (params, { dispatch, rejectWithValue }) => {
        const { id, status } = params
        try {
            const response = await $api.patch(`${$url}/quotes/${id}`, { status });
            return response.data;
        } catch (error) {
            console.log('change quote status error', error);
            return rejectWithValue(error.response.data)
        }
    }
);

export const removeQuote = createAsyncThunk(
    "users/removeQuote",
    async (id, { dispatch, getState, rejectWithValue }) => {
        try {
            await $api.delete(`${$url}/quotes/${id}`);
            return id;
        } catch (error) {
            console.log('delete quotes error', error.response.data);
            return rejectWithValue(error.response.data)
        }
    }
);

export const getPDF = createAsyncThunk('get-pdf', (id) => {
    $api.get(`${$url}/quotes/${id}/pdf`, { responseType: 'arraybuffer' })
        .then(({ data: pdf }) => {
            const blob = new Blob([pdf], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `qoute-${id}.pdf`;
            link.dispatchEvent(new MouseEvent('click'));

            // open in new window
            // const link = URL.createObjectURL(blob);
            // window.open(link);
        })
});

export const quotesSlice = createSlice({
    name: "quotes",
    initialState: {
        data: [],
        params: {
            search: "",
            sort: "",
            order: "",
            page: 1,
        },
        loading: "idle",
        total: null,
        error: null
    },
    reducers: {},
    extraReducers: {
        [getData.pending]: (state) => {
            state.loading = "pending";
        },
        [getData.fulfilled]: (state, { payload }) => {
            state.data = payload.data;
            state.params = payload.params;
            state.total = payload.total;
            state.loading = "idle";
        },
        [getData.rejected]: (state, action) => {
            state.loading = 'failed'
            console.log("error fetching quotes", action)
            state.error = action.error.message || ''
        },
        [updateQuoteStatus.pending]: (state) => {
            state.loading = "pending";
        },
        [updateQuoteStatus.fulfilled]: (state, { payload }) => {
            state.data = payload.data;
            state.loading = "idle";
        },
        [updateQuoteStatus.rejected]: (state, action) => {
            state.loading = 'failed'
            console.log("error quote status changing", action)
            state.error = action.error.message || ''
        },
        [getCustomerQuotes.pending]: (state) => {
            state.loading = "pending";
        },
        [getCustomerQuotes.fulfilled]: (state, { payload }) => {
            state.data = payload.data;
            state.total = payload.data.total;
            state.loading = "idle";
        },
        [getCustomerQuotes.rejected]: (state, action) => {
            state.loading = 'failed'
            console.log("error get customer quotes", action)
            state.error = action.error.message || ''
        },
        [removeQuote.pending]: (state) => {
            state.loading = "pending";
        },
        [removeQuote.fulfilled]: (state, { payload }) => {
            state.data = payload.data;
            state.loading = "idle";
        },
        [removeQuote.rejected]: (state, action) => {
            state.loading = 'failed'
            console.log("error remove quote", action)
            state.error = action.error.message || ''
        },
    },
});

export const quotesEntities = ({ quotes }) => {
    const isLoading = quotes.loading === "pending";
    return { quotes: quotes.data, isLoading, total: quotes.total, error: quotes.error };
};

export default quotesSlice.reducer;
