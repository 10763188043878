import { Box, DollarSign, Eye, Home, LifeBuoy, Settings, User, Users } from 'react-feather'

const userData = JSON.parse(localStorage.getItem('userData'))
const role = userData?.role && userData.role.toLowerCase()

export default [
  {
    id: 'main',
    title: 'Main',
    icon: <Box size={20} />,
    navLink: '/dashboard'
  },
  {
    id: 'quotes',
    title: 'Quotes',
    icon: <DollarSign size={20} />,
    navLink: '/quotes'
  },
  {
    id: 'customers',
    title: 'My Customers',
    icon: <Users size={20} />,
    navLink: '/customers'
  },
  {
    id: 'settings',
    title: 'Settings',
    icon: <Settings size={20} />,
    children: [
      {
        id: 'company',
        title: 'My Company',
        navLink: '/settings/company',
        icon: <Eye size={20} />,
        permissions: ['admin', 'manager', 'user'],
      },
      {
        id: 'services',
        title: 'Services',
        navLink: '/settings/services',
        icon: <LifeBuoy size={20} />,
        permissions: ['admin', 'manager', 'user'],
      },
      {
        id: 'users',
        title: 'Users',
        permissions: ['admin'],
        navLink: '/settings/users',
        icon: <User size={20} />,
        allowedRoles: 'admin'
      },
    ]
  }
]