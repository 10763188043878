// ** Vertical Menu Components
import VerticalNavMenuLink from './VerticalNavMenuLink'
import VerticalNavMenuGroup from './VerticalNavMenuGroup'
import VerticalNavMenuSectionHeader from './VerticalNavMenuSectionHeader'

// ** Utils
import {
  resolveVerticalNavMenuItemComponent as resolveNavItemComponent,
  canViewHorizontalNavMenuItem
} from '@layouts/utils'
import { useSelector } from 'react-redux'

const VerticalMenuNavItems = props => {

  const userData = useSelector(state => state.auth.userData)
  const role = userData?.role
  const company = userData?.company


  // ** Components Object
  const Components = {
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    VerticalNavMenuSectionHeader
  }

  // ** Render Nav Menu Items
  const RenderNavItems = props.items.map((item, index) => {
    const TagName = Components[resolveNavItemComponent(item)]
    if (item.children) {
      return <TagName item={item} index={index} key={item.id} {...props} />
    } else {
      return canViewHorizontalNavMenuItem(item, role.toLowerCase()) && <TagName item={item} index={index} key={item.id} {...props} />
    }
  })

  return RenderNavItems
}

export default VerticalMenuNavItems
