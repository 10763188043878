// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import $api, { $url } from "../configs/axiosConfig";
import { updateUserData as updateAuthUser } from "./authentication";

export const getData = createAsyncThunk(
    "users/getData", async (params, { rejectWithValue }) => {
        try {
            const response = await $api.get(`${$url}/users`, { params });
            const usersData = response.data.users?.map(data => ({
                ...data,
                role: { value: data.role, label: data.role },
            }))
            return {
                params,
                data: usersData,
                total: response.data.total
            };
        } catch (error) {
            console.log('fetch users error', error.response.data);
            return rejectWithValue(error.response.data)
        }
    });

export const addUser = createAsyncThunk(
    "users/addUser",
    async (user, { dispatch, getState, rejectWithValue }) => {
        try {
            const response = await $api.post(`${$url}/users/new`, { ...user });
            await dispatch(getData(getState().users.params));
            return response.data;
        } catch (error) {
            console.log('add user error', error.response.data);
            return rejectWithValue(error.response.data)
        }
    }
);

export const updateUser = createAsyncThunk(
    "users/updateUser",
    async ({ id, user }, { dispatch, getState, rejectWithValue }) => {
        try {
            const { data } = await $api.put(`${$url}/users/${id}`, { ...user });
            if (id === getState().auth.userData?.id) {
                console.log('same id');
                dispatch(updateAuthUser(data.data.user));
            }
            return data;
        } catch (error) {
            console.log('update user error', error.response.data);
            return rejectWithValue(error.response.data)
        }
    }
);

export const removeUser = createAsyncThunk(
    "users/removeUser",
    async (id, { dispatch, getState, rejectWithValue }) => {
        try {
            await $api.delete(`${$url}/users/${id}`);
            await dispatch(getData(getState().users.params));
            return id;
        } catch (error) {
            console.log('delete user error', error.response.data);
            return rejectWithValue(error.response.data)
        }
    }
);

export const usersSlice = createSlice({
    name: "users",
    initialState: {
        data: [],
        params: {
            role: "",
            sort: "",
            order: "",
            page: 1,
        },
        loading: "idle",
        total: null,
        error: null
    },
    reducers: {},
    extraReducers: {
        [getData.pending]: (state) => {
            state.loading = "pending";
        },
        [getData.fulfilled]: (state, { payload }) => {
            state.data = payload.data;
            state.params = payload.params;
            state.total = payload.total;
            state.loading = "idle";
        },
        [getData.rejected]: (state, action) => {
            state.loading = 'failed'
            console.log("error fetching customers", action)
            state.error = action.error.message || ''
        },
        [addUser.pending]: (state) => {
            state.loading = "pending";
        },
        [addUser.fulfilled]: (state, { payload }) => {
            state.data = payload.data;
            state.loading = "idle";
        },
        [addUser.rejected]: (state, action) => {
            state.loading = 'failed'
            console.log("error fetching customers", action)
            state.error = action.error.message || ''
        },
        [updateUser.pending]: (state) => {
            state.loading = "pending";
        },
        [updateUser.fulfilled]: (state, { payload }) => {
            state.data = payload.data;
            state.loading = "idle";
        },
        [updateUser.rejected]: (state, action) => {
            state.loading = 'failed'
            console.log("error fetching customers", action)
            state.error = action.error.message || ''
        },
        [removeUser.pending]: (state) => {
            state.loading = "pending";
        },
        [removeUser.fulfilled]: (state, { payload }) => {
            state.data = payload.data;
            state.loading = "idle";
        },
        [removeUser.rejected]: (state, action) => {
            state.loading = 'failed'
            console.log("error fetching customers", action)
            state.error = action.error.message || ''
        },
    },
});

export const usersEntities = ({ users }) => {
    const isLoading = users.loading === "pending";
    return { users: users.data, isLoading, total: users.total, error: users.error };
};

export default usersSlice.reducer;
