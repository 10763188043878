// ** React Imports
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import CreateCompany from '../../views/pages/settings/company/CreateCompany';

const Home = lazy(() => import('../../views/pages/Home'));
const Dashboard = lazy(() => import('../../views/pages/Dashboard'));
const Customers = lazy(() => import('../../views/pages/customers'));
const CustomerPage = lazy(() =>
  import('../../views/pages/customers/customerPage')
);
const Users = lazy(() => import('../../views/pages/settings/users'));
const Services = lazy(() => import('../../views/pages/settings/services'));
const Company = lazy(() => import('../../views/pages/settings/company'));
const Quotes = lazy(() => import('../../views/pages/quotes'));
const QuotePreview = lazy(() => import('../../views/pages/quote/preview'));

const CreateQuote = lazy(() => import('../../views/pages/quote/create/index'));

const userData = JSON.parse(localStorage.getItem('userData'));
const company = userData?.company;
const role = userData?.role.toLowerCase();

let PageRoutes = [];

if (!company) {
  PageRoutes = [
    {
      path: '/dashboard',
      element: <CreateCompany />,
      meta: {
        menuHidden: true,
        restricted: true,
      },
    },
    {
      path: '*',
      meta: {
        menuHidden: true,
      },
      element: <Navigate to="/home" replace />,
    },
  ];
} else {
  PageRoutes = [
    {
      path: '/home',
      element: <Navigate replace to="/dashboard" />,
    },
    {
      path: '/dashboard',
      element: <Dashboard />,
    },
    {
      path: '/customers',
      element: <Customers />,
    },
    {
      path: '/customers/:id',
      element: <CustomerPage />,
    },
    {
      path: '/settings/users',
      element: <Users />,
    },
    {
      path: '/settings/services',
      element: <Services />,
    },
    {
      path: '/settings/company',
      element: <Company />,
    },
    {
      path: '/quotes/new',
      element: <CreateQuote />,
    },
    {
      path: '/quotes/:id',
      element: <CreateQuote />,
    },
    {
      path: '/quotes',
      element: <Quotes />,
    },
    {
      path: '/quotes/preview/:id',
      element: <QuotePreview />,
    },
    {
      path: '*',
      element: <Navigate replace to="/dashboard" />,
    },
  ];
}

export default PageRoutes;
