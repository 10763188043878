import { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Card, CardHeader, CardBody, CardTitle, CardText, Form, Row, Col, Label, Input, Button, Spinner, FormFeedback } from 'reactstrap'
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast'

import { useDispatch, useSelector } from "react-redux";
import {
    getData,
    addCompany,
    companyEntities
} from "@redux/company";
import { updateUser } from "@redux/users"
import { handleLogin } from '@store/authentication'

const CreateCompany = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { company, isLoading } = useSelector(companyEntities);
    const userData = useSelector(state => state.auth.userData);

    if (userData?.role !== 'Admin' || userData?.company) {
        window.location.href = '/';
    }

    const { handleSubmit, control, formState: { errors }, reset } = useForm({
        defaultValues: {
            companyNameEnglish: '',
            companyNameHebrew: '',
            bnNumber: '',
        }
    });

    const fieldOptions = {
        companyNameEnglish: { required: 'Company Name is required' },
        companyNameHebrew: { required: 'Company Name is required' },
        bnNumber: { required: 'BN Number is required' },
    };

    const onSubmit = async data => {
        console.log('submit', data);
        try {
            const companyState = {
                companyNameEnglish: data.companyNameEnglish,
                companyNameHebrew: data.companyNameHebrew,
                bnNumber: data.bnNumber,
                email: data.email && data.email,
                phoneNumber: data.phoneNumber && data.phoneNumber,
                faxNumber: data.faxNumber && data.faxNumber,
                addressEnglish: data.addressEnglish && data.addressEnglish,
                addressHebrew: data.addressHebrew && data.addressHebrew,
                website: data.website && data.website
            }

            const userState = {
                email: userData.email,
                firstName: userData.firstName,
                lastName: userData.lastName,
                role: userData.role
            }

            const resultAction = await dispatch(addCompany({ ...companyState }))
            console.log('create company result action', resultAction);

            if (resultAction?.payload?.statusCode !== 200) {
                toast.error(`${resultAction.payload.message}`)
            } else {
                toast.success(`${data.companyNameEnglish} created`);
                const userDataUpdatedResult = await dispatch(updateUser({ id: userData._id, user: userState }))
                const updatedData = {
                    userData: userDataUpdatedResult?.payload.data.user,
                    accessToken: userDataUpdatedResult?.payload.data.accessToken,
                    refreshToken: userDataUpdatedResult?.payload.data.refreshToken
                }
                await dispatch(handleLogin(updatedData))
                console.log('userDataUpdatedResult', userDataUpdatedResult);
                // navigate('/dashboard')
                navigate(0);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        dispatch(getData());
    }, [dispatch, company?.length]);

    return (
        <div className='h-100'>
            <Card>
                <CardHeader>
                    <CardTitle className='font-large-1'>Create Your Company</CardTitle>
                </CardHeader>
                <CardBody>

                    {isLoading ? (
                        <div className='d-flex justify-content-center'>
                            <Spinner color='primary' />
                        </div>
                    ) : (
                        <>
                            <CardText>Fill the information about your company / buisness</CardText>

                            <Form className='mt-2' onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col sm='4' className='mb-1'>
                                        <Label className='form-label' for='companyNameEnglish'>
                                            Company Name(English) <span className='text-danger'>*</span>
                                        </Label>
                                        <Controller
                                            id='companyNameEnglish'
                                            name='companyNameEnglish'
                                            rules={fieldOptions.companyNameEnglish}
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    autoFocus
                                                    type='text'
                                                    placeholder='Company Name(English)'
                                                    invalid={errors.companyNameEnglish && true}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        {errors.companyNameEnglish && <FormFeedback>{errors.companyNameEnglish.message}</FormFeedback>}
                                    </Col>
                                    <Col sm='4' className='mb-1'>
                                        <Label className='form-label' for='companyNameHebrew'>
                                            Company Name(Hebrew) <span className='text-danger'>*</span>
                                        </Label>
                                        <Controller
                                            id='companyNameHebrew'
                                            name='companyNameHebrew'
                                            rules={fieldOptions.companyNameHebrew}
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    type='text'
                                                    placeholder='Company Name(Hebrew)'
                                                    invalid={errors.companyNameHebrew && true}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        {errors.companyNameHebrew && <FormFeedback>{errors.companyNameHebrew.message}</FormFeedback>}
                                    </Col>
                                    <Col sm='4' className='mb-1'>
                                        <Label className='form-label' for='bnNumber'>
                                            BN Number <span className='text-danger'>*</span>
                                        </Label>
                                        <Controller
                                            id='bnNumber'
                                            name='bnNumber'
                                            rules={fieldOptions.bnNumber}
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    type='number'
                                                    placeholder='BN Number'
                                                    invalid={errors.bnNumber && true}
                                                    {...field}
                                                />
                                            )}
                                        />
                                        {errors.bnNumber && <FormFeedback>{errors.bnNumber.message}</FormFeedback>}
                                    </Col>
                                    <Col sm='4' className='mb-1'>
                                        <Label className='form-label' for='email'>
                                            Email(For general inquiries)
                                        </Label>
                                        <Controller
                                            id='email'
                                            name='email'
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    type='email'
                                                    placeholder='Email'
                                                    invalid={errors.email && true}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col sm='4' className='mb-1'>
                                        <Label className='form-label' for='phoneNumber'>
                                            Company Phone Number
                                        </Label>
                                        <Controller
                                            id='phoneNumber'
                                            name='phoneNumber'
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    type='number'
                                                    placeholder='Phone Number'
                                                    invalid={errors.phoneNumber && true}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col sm='4' className='mb-1'>
                                        <Label className='form-label' for='faxNumber'>
                                            Company Fax Number
                                        </Label>
                                        <Controller
                                            id='faxNumber'
                                            name='faxNumber'
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    type='number'
                                                    placeholder='Fax Number'
                                                    invalid={errors.faxNumber && true}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col sm='4' className='mb-1'>
                                        <Label className='form-label' for='addressEnglish'>
                                            Address(English)
                                        </Label>
                                        <Controller
                                            id='addressEnglish'
                                            name='addressEnglish'
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    type='text'
                                                    placeholder='Address(English)'
                                                    invalid={errors.addressEnglish && true}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col sm='4' className='mb-1'>
                                        <Label className='form-label' for='addressHebrew'>
                                            Address(Hebrew)
                                        </Label>
                                        <Controller
                                            id='addressHebrew'
                                            name='addressHebrew'
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    type='text'
                                                    placeholder='Address(Hebrew)'
                                                    invalid={errors.addressHebrew && true}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col sm='4' className='mb-1'>
                                        <Label className='form-label' for='website'>
                                            Website
                                        </Label>
                                        <Controller
                                            id='website'
                                            name='website'
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    type='text'
                                                    placeholder='Website'
                                                    invalid={errors.website && true}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Col>
                                    <Col sm='12' className='mt-3'>
                                        <div className='d-flex justify-content-end'>
                                            <Button color='primary' type='submit'>
                                                Create
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    )}
                </CardBody>
            </Card>
        </div >
    )
}

export default CreateCompany
