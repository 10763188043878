import { Mail, Home, Box, DollarSign, Users, Settings, Eye, LifeBuoy, User } from 'react-feather'

export default [
  // {
  //   id: 'home',
  //   title: 'Home',
  //   icon: <Home size={20} />,
  //   navLink: '/'
  // }
  {
    id: 'main',
    title: 'Main',
    icon: <Box size={20} />,
    navLink: '/dashboard'
  },
  {
    id: 'quotes',
    title: 'Quotes',
    icon: <DollarSign size={20} />,
    navLink: '/quotes'
  },
  {
    id: 'customers',
    title: 'My Customers',
    icon: <Users size={20} />,
    navLink: '/customers'
  },
  {
    id: 'settings',
    title: 'Settings',
    icon: <Settings size={20} />,
    children: [
      {
        id: 'company',
        title: 'My Company',
        // permissions: ['admin'],
        navLink: '/settings/company',
        icon: <Eye size={20} />,
      },
      {
        id: 'services',
        title: 'Services',
        // permissions: ['admin'],
        navLink: '/settings/services',
        icon: <LifeBuoy size={20} />,
      },
      {
        id: 'users',
        title: 'Users',
        // permissions: ['admin'],
        navLink: '/settings/users',
        icon: <User size={20} />,
      },
    ]
  }
]
