// ** Menu Components Imports
import HorizontalNavMenuLink from './HorizontalNavMenuLink'
import HorizontalNavMenuGroup from './HorizontalNavMenuGroup'
import {
  resolveHorizontalNavMenuItemComponent as resolveNavItemComponent,
  canViewHorizontalNavMenuItem,
} from '@layouts/utils'
import { useSelector } from 'react-redux'

const HorizontalNavMenuItems = props => {

  const userData = useSelector(state => state.auth.userData)
  const role = userData?.role
  const company = userData?.company

  // ** Components Object
  const Components = {
    HorizontalNavMenuGroup,
    HorizontalNavMenuLink
  }

  // ** Render Nav Items
  const RenderNavItems = props.items.map((item, index) => {
    const TagName = Components[resolveNavItemComponent(item)]
    if (item.children) {
      return <TagName item={item} index={index} key={item.id} {...props} />
    } else {
      return canViewHorizontalNavMenuItem(item, role.toLowerCase()) && <TagName item={item} index={index} key={item.id} {...props} />
    }
  })

  return RenderNavItems
}

export default HorizontalNavMenuItems
